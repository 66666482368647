import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import React from "react";
import HtmlLinksDecoration from "../../frontend/HtmlLinksDecoration";
import Img from "../../frontend/Image";
import AimsProgramsInput from "../../inputs/AimsProgramsInput";
import ProgramForm from "./ProgramForm";

function Head({ telephone_cta, telephone_label, telephone_number }) {
  return (
    <div className="header-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-section">
              <div className="aims-logo">
                <picture>
                  <source
                    srcSet="/logos/aims-logo-small-1.avif 1x, /logos/aims-logo-small-1@2x.avif 2x"
                    type="image/avif"
                  />

                  <source
                    srcSet="/logos/aims-logo-small-1.png 1x, /logos/aims-logo-small-1@2x.png 2x"
                    type="image/png"
                  />

                  <img
                    src="/logos/aims-logo-small-1.png"
                    alt="AIMS Education"
                    className="small-logo"
                  />
                </picture>

                <picture>
                  <source
                    srcSet="/logos/aims-logo-large-1.avif 1x, /logos/aims-logo-large-1@2x.avif 2x"
                    type="image/avif"
                  />

                  <source
                    srcSet="/logos/aims-logo-large-1.png 1x, /logos/aims-logo-large-1@2x.png 2x"
                    type="image/png"
                  />

                  <img
                    src="/logos/aims-logo-large-1.png"
                    alt="AIMS Education"
                    className="large-logo"
                  />
                </picture>
              </div>

              <div>
                <h2>{telephone_cta}</h2>
                <a href={`tel:${telephone_number}`}>{telephone_label}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Main({ background, background_mobile, desktop_height, title }) {
  return (
    <div className="main-section">
      <Img
        src={background}
        variant="lead"
        className={`background ${desktop_height}`}
      />
      <Img
        src={background_mobile}
        variant="image"
        className="background-mobile"
      />

      <div className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-1"></div>

            <div className="col-11">
              <h1 className="title">{title}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Bottom({
  description,
  form_title,
  form_disclaimer,
  form_button,
  form_checkbox_1,
  form_checkbox_2,
  form_checkbox_3,
  form_checkbox_4,
  form_programs,
}) {
  return (
    <div className="bottom-section">
      <div className="container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-6">
            {description !== "<p><br></p>" && (
              <HtmlLinksDecoration className="description" text={description} />
            )}
          </div>
          <div className="col-4">
            <ProgramForm
              form_title={form_title}
              form_disclaimer={form_disclaimer}
              form_button={form_button}
              form_checkbox_1={form_checkbox_1}
              form_checkbox_2={form_checkbox_2}
              form_checkbox_3={form_checkbox_3}
              form_checkbox_4={form_checkbox_4}
              form_programs={form_programs}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const ProgramHeroWidget = ({
  background,
  background_mobile,
  desktop_height,
  title,
  telephone_cta,
  telephone_label,
  telephone_number,
  description,
  form_title,
  form_disclaimer,
  form_button,
  form_checkbox_1,
  form_checkbox_2,
  form_checkbox_3,
  form_checkbox_4,
  form_programs,
  containerSettings,
}) => {
  const [isShrunk, setShrunk] = React.useState(false);

  React.useEffect(() => {
    const handler = () => {
      setShrunk(window.scrollY > 10);
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <Container settings={containerSettings}>
      <div className={`program-hero ${isShrunk ? "is-shrunk" : ""}`}>
        <Head
          telephone_cta={telephone_cta}
          telephone_label={telephone_label}
          telephone_number={telephone_number}
        />

        <Main
          background={background}
          background_mobile={background_mobile}
          desktop_height={desktop_height}
          title={title}
        />

        <Bottom
          description={description}
          form_title={form_title}
          form_disclaimer={form_disclaimer}
          form_button={form_button}
          form_checkbox_1={form_checkbox_1}
          form_checkbox_2={form_checkbox_2}
          form_checkbox_3={form_checkbox_3}
          form_checkbox_4={form_checkbox_4}
          form_programs={form_programs}
        />
      </div>
    </Container>
  );
};

ProgramHeroWidget.title = "Program hero";
ProgramHeroWidget.category = "Media";

ProgramHeroWidget.defaults = {
  background: "https://placehold.it/1920x1080",
  desktop_height: "medium",
  title: "Page Hero",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas velit debitis doloremque et dolor ad in, at modi nulla, dolores obcaecati. Labore maxime doloribus sapiente, ipsam blanditiis corrupti aspernatur cumque?</p>",
  telephone_cta: "Get Started Today!",
  telephone_label: "Call 555-555-555",
  telephone_number: "555-555-555",
  form_title: "Get the Details!",
  form_disclaimer:
    "<p>By clicking the submit button, I give AIMS Education permission to call, text, and/or email me information about its educational programs at the phone number and email address provided. I acknowledge my consent is not required to obtain any good or service. I can opt-out by contacting AIMS Education at 908-222-0002.</p>",
  form_button: "Get Program Details Now!",
  form_checkbox_1: "Program Tuition",
  form_checkbox_2: "Program Start Date",
  form_checkbox_3: "Financial Aid",
  form_checkbox_4: "Job Prospects",
  form_programs: AimsProgramsInput.defaults,
  containerSettings: {},
};

ProgramHeroWidget.adminFields = {
  background: { type: "custom", component: ImagePicker },
  desktop_height: {
    type: "select",
    options: [
      ["short", "Short"],
      ["medium", "Medium"],
      ["long", "Long"],
    ],
  },
  background_mobile: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  telephone_cta: { type: "text" },
  telephone_label: { type: "text" },
  telephone_number: { type: "text" },
  form_title: { type: "text" },
  form_disclaimer: { type: "custom", component: RichText },
  form_button: { type: "text" },
  form_checkbox_1: { type: "text" },
  form_checkbox_2: { type: "text" },
  form_checkbox_3: { type: "text" },
  form_checkbox_4: { type: "text" },
  form_programs: {
    type: "custom",
    component: AimsProgramsInput,
  },
};

export default ProgramHeroWidget;
