import React from "react";
import Input from "../UncontrolledInput";

const OpenHouseForm = ({ programs, events, token }) => {
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <form
      method="POST"
      action="/open-house-registration"
      onSubmit={() => {
        setSubmitting(true);
      }}
    >
      <Input type="hidden" name="authenticity_token" value={token} />

      {events.length === 1 && (
        <Input
          type="hidden"
          name="open_house[event_type]"
          id="open_house_event_type"
          value={events?.[0]?.value}
          required
        />
      )}

      {events.length > 1 && (
        <Input
          type="select"
          name="open_house[event_type]"
          label="Which event are you attending?"
          placeholder=""
          id="open_house_event_type"
          options={events}
          required
        />
      )}

      <Input
        type="text"
        name="open_house[first_name]"
        id="open_house_first_name"
        label="First Name"
        required
        maxLength="12"
      />

      <Input
        type="text"
        name="open_house[last_name]"
        id="open_house_last_name"
        label="Last Name"
        required
        maxLength="25"
      />

      <Input
        type="restirctiveText"
        name="open_house[phone]"
        id="open_house_phone"
        label="Phone"
        required
        maxLength="10"
        allowedCharacters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
        placeholder="Please enter a 10 digit phone number"
      />

      <Input
        type="email"
        name="open_house[email]"
        id="open_house_email"
        label="Email"
        required
      />

      <Input
        type="restirctiveText"
        name="open_house[zip]"
        id="open_house_zip"
        label="Zip"
        required
        maxLength="5"
        allowedCharacters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
        placeholder="Please enter a 5 digit ZIP code"
      />

      <Input
        type="select"
        name="open_house[program_1]"
        label="Program of interest"
        placeholder=""
        id="open_house_program_1"
        options={programs}
        required
      />

      <Input
        type="number"
        name="open_house[number_of_people]"
        placeholder="Number of People"
        label="Number of People Attending"
        id="open_house_number_of_people"
        required
      />

      <Input
        type="checkbox"
        name="open_house[terms]"
        defaultValue="accept"
        required
        labelClass="checkbox-label-grid"
        label={() => (
          <small>
            By clicking the submit button, I give AIMS Education permission to
            call, text, and/or email me information about its educational
            programs at the phone number and email address provided. I
            acknowledge my consent is not required to obtain any good or
            service. I can opt-out by contacting AIMS Education at 908-222-0002.
          </small>
        )}
      />

      <button type="submit" className="btn-primary lg" disabled={submitting}>
        Submit
      </button>
    </form>
  );
};

export default OpenHouseForm;
